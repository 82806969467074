import { useAppContext } from "@context/app";
import ImagePrepare from "@elements/imagePrepare";
import useParentSize from "@hooks/useParentSize";
import { IEntityInput, IHomeItemInput } from "@types";
import { actionGenerator } from "@utils/actionGenerator";
import { linkGenerator } from "@utils/linkGenerator";
import { segmentEvent } from "@utils/segment";
import Link from "next/link";
import { useRouter } from "next/router";

interface WidgetProps {
  data: IHomeItemInput;
  sourceEntity?: IEntityInput;

}

const Widget: React.FC<WidgetProps> = ({ data, sourceEntity }) => {
  const { country, user } = useAppContext()
  const { locale } = useRouter()
  const [size, parentRef] = useParentSize()
  return (
    <div className="w-full flex  max-w-[1240px] m-auto items-center justify-center">
      {data.items.map((item) => {
        let target;
        let rel;

        if (item.action.includes("openURL")) {
          if (item.action.includes("openURLinApp")) {
            target = "_self";
            rel = "";
          } else {
            target = "_blank";
            rel = "noopener noreferrer";
          }
        } else {
          target = "_self";
          rel = "";
        }
        return (
          <div
            className="sm:px-2 xs:px-1 px-1 overflow-hidden rounded-xl w-1/2 max-w-[286px]"
            key={item.id}
          >
            <Link href={linkGenerator(item.action, { source: "homepage", sourceEntity: "home", sourceSlug: sourceEntity?.slug, section: "", sectionEntity: "widget", sectionSlug: item.slug, target_slug: item.target?.slug || "" })} passHref prefetch={false}>
              <a
                target={target}
                rel={rel}

                onClick={() => {
                  segmentEvent("Widget Viewed", {
                    widget_id: item.id,
                    widget_slug: item.slug,
                    user_id: user.data?.id || -1,
                    country_id: country?.id,
                    country_slug: country?.slug,
                    language: locale?.includes("en") ? "English" : "Arabic",
                    target_id: item.target?.id || -1,
                    target_slug: item.target?.slug || "",
                    url: item.action.includes("openURL") ? linkGenerator(item.action) : "",
                    action: actionGenerator(item.action),
                    from_page: "homepage",
                    from_entity: "home",
                    from_entity_slug: sourceEntity?.slug || "",
                    from_page_section: "",
                    from_page_section_entity: "widget",
                    from_page_section_slug: item.slug || "",
                  })

                  if (actionGenerator(item.action) === "Category") {
                    segmentEvent("Category Viewed", {
                      user_id: user.data?.id || -1,
                      country_id: country?.id,
                      country_slug: country?.slug,
                      language: locale?.includes("en") ? "English" : "Arabic",
                      category_id: item.target?.id || -1,
                      category_slug: item.target?.slug || "",
                      from_page: "homepage",
                      from_entity: "home",
                      from_entity_slug: sourceEntity?.slug || "",
                      from_page_section: "",
                      from_page_section_entity: "widget",
                      from_page_section_slug: item.slug || "",
                    });
                  }
                  if (actionGenerator(item.action) === "Categories List") {
                    segmentEvent("All Categories Viewed", {
                      user_id: user.data?.id || -1,
                      country_id: country?.id,
                      country_slug: country?.slug,
                      language: locale?.includes("en") ? "English" : "Arabic",
                      from_page: "homepage",
                      from_entity: "home",
                      from_entity_slug: sourceEntity?.slug || "",
                      from_page_section: "",
                      from_page_section_entity: "widget",
                      from_page_section_slug: item.slug || "",
                    })
                  }
                  if (actionGenerator(item.action) === "Brand") {
                    segmentEvent("Brand Viewed", {
                      user_id: user.data?.id || -1,
                      country_id: country?.id,
                      country_slug: country?.slug,
                      language: locale?.includes("en") ? "English" : "Arabic",
                      brand_id: item.target?.id || -1,
                      brand_slug: item.target?.slug || "",
                      from_page: "homepage",
                      from_entity: "home",
                      from_entity_slug: sourceEntity?.slug || "",
                      from_page_section: "",
                      from_page_section_entity: "widget",
                      from_page_section_slug: item.slug || "",
                    })
                  }
                  if (actionGenerator(item.action) === "Collection") {
                    segmentEvent("Collection Viewed", {
                      user_id: user.data?.id || -1,
                      country_id: country?.id,
                      country_slug: country?.slug,
                      language: locale?.includes("en") ? "English" : "Arabic",
                      collection_id: item.target?.id || -1,
                      collection_slug: item.target?.slug || "",
                      from_page: "homepage",
                      from_entity: "home",
                      from_entity_slug: sourceEntity?.slug || "",
                      from_page_section: "",
                      from_page_section_entity: "widget",
                      from_page_section_slug: item.slug || "",
                    })
                  }
                }

                }
              >
                <div className="w-full pt-[50%] relative">
                  <div className=" w-full h-full absolute inset-0">
                    <div className="w-full h-full">
                      <div className="relative w-full h-full overflow-hidden rounded-xl  scale-1  transition-transform duration-200 ease-in-out hover:scale-[1.05]" ref={parentRef}>
                        <ImagePrepare media={item.media} alt={item.name || "Dabdoob"} width={size.width} />
                      </div>
                    </div>
                  </div>
                </div>

              </a>
            </Link>
          </div>
        )
      })}
    </div>
  );
};

export default Widget;
