import { useAppContext } from "@context/app";
import { segmentEvent } from "@utils/segment";

import { useRouter } from "next/router";
import { ISourceEvent } from "./useEvents";
import { linkGenerator } from "@utils/linkGenerator";
import { actionGenerator } from "@utils/actionGenerator";

function useBannerViewed() {
  const { user, country } = useAppContext();
  const { locale } = useRouter();

  const bannerViewed = (
    source: ISourceEvent,
    banner: { id?: number; slug?: string },
    target: { id?: number; slug?: string },
    action: string
  ) => {
    segmentEvent("Banner Viewed", {
      user_id: user.data?.id || -1,
      country_id: country?.id,
      country_slug: country?.slug,
      language: locale?.includes("en") ? "English" : "Arabic",
      banner_id: banner.id ?? -1,
      banner_slug: banner.slug,
      target_id: target.id ?? -1,
      target_slug: target.slug ?? "",
      url: action.includes("openURL") ? linkGenerator(action) : "",
      action: actionGenerator(action),
      ...source,
    });
  };

  return { bannerViewed };
}

export default useBannerViewed;
