import { useAppContext } from "@context/app";
import { segmentEvent } from "@utils/segment";

import { useRouter } from "next/router";
import { ISourceEvent } from "./useEvents";

function useCollectionViewed() {
  const { user, country } = useAppContext();
  const { locale } = useRouter();

  const collectionViewed = (source: ISourceEvent, collection: { id?: number; slug?: string }) => {
    segmentEvent("Collection Viewed", {
      user_id: user.data?.id || -1,
      country_id: country?.id,
      country_slug: country?.slug,
      language: locale?.includes("en") ? "English" : "Arabic",
      collection_id: collection.id ?? -1,
      collection_slug: collection.slug,
      ...source,
    });
  };

  return { collectionViewed };
}

export default useCollectionViewed;
