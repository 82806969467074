import Link from "next/link";
import { IEntityInput, IHomeItemInput } from "@types";
import { ChevronRightIcon } from "@heroicons/react/solid";
import CardProduct from "@elements/productCard/productCard";
import { useRouter } from "next/router";
import { segmentEvent } from "@utils/segment";
import { useAppContext } from "@context/app";
import { useKeenSlider } from "keen-slider/react"
import { linkGenerator } from "@utils/linkGenerator";

interface CollectionListProps {
  data: IHomeItemInput;
  sourceEntity?: IEntityInput;
}


const CollectionList: React.FC<CollectionListProps> = ({ data, sourceEntity }) => {
  const { locale } = useRouter();
  const { country, user } = useAppContext()
  const [sliderRef] = useKeenSlider<HTMLDivElement>({
    loop: false,
    rubberband: false,
    mode: "free-snap",
    renderMode: "performance",
    rtl: locale?.includes("en-") ? false : true,
    slides: { perView: "auto", spacing: 10 },
  }, [])

  return (
    <div className="w-full max-w-[1240px] m-auto newProduct-container ">
      <div className="w-full flex justify-between items-center space-x-2">
        <h1 className=" flex-1 font-extrabold sm:text-2xl xs:text-lg text-lg text-[#222222] whitespace-nowrap text-ellipsis overflow-hidden ">
          {data.header?.name}
        </h1>
        {data.header?.more ? (
          <Link
            href={linkGenerator(data.header.more.action)}
            passHref
            prefetch={false}
          >
            <a onClick={() => {
              segmentEvent("Collection Viewed", {
                user_id: user.data?.id || -1,
                country_id: country?.id,
                country_slug: country?.slug,
                language: locale?.includes("en") ? "English" : "Arabic",
                collection_id: data.entity?.id,
                collection_slug: data.entity?.slug || "",
                from_page: "homepage",
                from_entity: "home",
                from_entity_slug: sourceEntity?.slug || "",
                from_page_section: "Collection",
                from_page_section_entity: "",
                from_page_section_slug: "",
              })
            }}>
              <div className={`${locale?.includes("ar") ? "pl-[11px] pr-5" : "pl-5 pr-[11px]"} rounded-full border h-10 flex items-center space-x-1 text-sm font-normal text-brandBlue py-2 `}>
                <span >{data.header.more.name}</span>
                <ChevronRightIcon
                  className={`${locale?.includes("ar") ? "rotate-180" : ""} h-6 w-6`}
                  aria-hidden="true"
                />
              </div>
            </a>
          </Link>
        ) : null}
      </div>
      <div className="w-full overflow-auto flex mt-5">
        <div ref={sliderRef} className="keen-slider max-w-full py-2">
          {data.items.map((product) => (
            <div key={product.default_sku_id} className="keen-slider__slide max-w-[194px] min-w-[194px] ">
              <CardProduct product={product} width={194} source="homepage" sourceEntity={"home"} sourceSlug={sourceEntity?.slug || ""} section={""} sectionEntity="collection" sectionSlug={data.entity?.slug} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CollectionList;
