import { IEntityInput, IHomeItemInput } from "@types";
import Link from "next/link";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { useRouter } from "next/router";
import { segmentEvent } from "@utils/segment";
import { useAppContext } from "@context/app";
import ImagePrepare from "@elements/imagePrepare";
import useParentSize from "@hooks/useParentSize";
import { useKeenSlider } from "keen-slider/react";
import { linkGenerator } from "@utils/linkGenerator";
import useEvents from "@hooks/useEvents";

interface Props {
  data: IHomeItemInput;
  sectionEntity?: IEntityInput;
}

const Brand: React.FC<Props> = ({ data, sectionEntity }) => {
  const { locale } = useRouter();
  const { country, user } = useAppContext()
  const { allBrandsViewed } = useEvents()
  const [parentSize, parentRef] = useParentSize();
  const [sliderRef] = useKeenSlider<HTMLDivElement>({
    loop: false,
    rubberband: false,
    mode: "free-snap",
    renderMode: "performance",
    rtl: locale?.includes("en-") ? false : true,
    slides: { perView: "auto", spacing: 10 },
  }, [])
  return (
    <div className="w-full max-w-[1240px] m-auto">
      <div className="w-full flex justify-between items-center space-x-2">
        <h1 className=" flex-1 font-extrabold sm:text-2xl xs:text-lg text-lg text-[#222222]  whitespace-nowrap text-ellipsis overflow-hidden ">
          {data.header?.name}
        </h1>
        {data.header?.more ? (
          <Link
            href={linkGenerator(data.header.more.action)}
            passHref
            prefetch={false}
          >
            <a onClick={() => allBrandsViewed({
              from_page: "homepage",
              from_entity: "home",
              from_entity_slug: sectionEntity?.slug || "",
              from_page_section: "Brands",
              from_page_section_entity: "",
              from_page_section_slug: "",
            })}>
              <div className={`${locale?.includes("ar-") ? "pl-[11px] pr-5" : "pl-5 pr-[11px]"} rounded-full border h-10 flex items-center space-x-1 text-sm font-normal text-brandBlue py-2 `}>
                <span >{data.header.more.name}</span>
                <ChevronRightIcon
                  className={`${locale?.includes("ar") ? "rotate-180" : ""} h-6 w-6`}
                  aria-hidden="true"
                />
              </div>
            </a>
          </Link>
        ) : null}
      </div>
      <div className="w-full mt-5">
        <div ref={sliderRef} className="keen-slider max-w-full ">
          {data.items.map((item) => (
            <div key={`brand-${item.id}`} className=" max-w-[160px] min-w-[160px] keen-slider__slide">
              <Link
                href={`/product?brand_id=${item.id}`}
                passHref
                prefetch={false}
              >
                <a
                  onClick={() => {
                    segmentEvent("Brand Viewed", {
                      user_id: user.data?.id || -1,
                      country_id: country?.id,
                      country_slug: country?.slug,
                      language: locale?.includes("en") ? "English" : "Arabic",
                      brand_id: item.id,
                      brand_slug: item.slug,
                      from_page: "homepage",
                      from_entity: "home",
                      from_entity_slug: sectionEntity?.slug || "",
                      from_page_section: "Brands",
                      from_page_section_entity: "",
                      from_page_section_slug: "",
                    });
                  }

                  }
                >
                  <div className=" w-40 h-40 relative border rounded-full overflow-hidden ">
                    <div className="relative   overflow-hidden  flex items-center justify-center w-full h-full scale-1  transition-transform duration-200 ease-in-out hover:scale-[1.05] ">
                      <div className=" w-28 h-28 rounded-lg overflow-hidden" ref={parentRef}>
                        <ImagePrepare alt={item.name} media={item.media} width={parentSize.width} />
                      </div>
                    </div>
                  </div>
                </a>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Brand;
