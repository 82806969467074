import { useAppContext } from "@context/app";
import { segmentEvent } from "@utils/segment";

import { useRouter } from "next/router";
import { ISourceEvent } from "./useEvents";

function useBrandView() {
  const { user, country } = useAppContext();
  const { locale } = useRouter();

  const brandViewed = (source: ISourceEvent, brand: { id?: number; slug?: string }) => {
    segmentEvent("Brand Viewed", {
      user_id: user.data?.id || -1,
      country_id: country?.id,
      country_slug: country?.slug,
      language: locale?.includes("en") ? "English" : "Arabic",
      brand_id: brand.id ?? -1,
      brand_slug: brand.slug,
      ...source,
    });
  };

  return { brandViewed };
}

export default useBrandView;
